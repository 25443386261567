import CoreTextProvider from "../CoreTextProvider"

export default class DefaultRuCoreTextProvider implements CoreTextProvider {
  loading(): string {
    return "Загрузка"
  }

  paymentPending(): string {
    return "Ожидает оплаты"
  }

  paymentProcessing(): string {
    return "Обработка"
  }

  paymentSuccess(): string {
    return "Оплата прошла успешно"
  }

  paymentError(): string {
    return "Ошибка оплаты"
  }
}
