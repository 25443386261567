import BackendHttpClient, { BackendHttpClientResult } from "../../network/BackendHttpClient"
import { HttpRequestType } from "../../../../lib/http-client/HttpClient"
import SuccessExecutionResult from "../../../domain/results/SuccessExecutionResult"
import NetworkPayment from "../../entites/entities/network/payments/NetworkPayment"
import ErrorExecutionResult from "../../../domain/results/ErrorExecutionResult"
import NetworkExecutionError from "../../entites/entities/network/errors/NetworkExecutionError"
import FailureExecutionResult from "../../../domain/results/FailureExecutionResult"
import { instanceToPlain, plainToInstance } from "class-transformer"
import NetworkPaymentResponseBody from "../../entites/entities/network/payments/NetworkPaymentResponseBody"
import NetworkPaymentError from "../../entites/entities/network/payments/NetworkPaymentError"
import assertNever from "../../../../lib/assertNever"
import NetworkPaymentRequestQuery from "../../entites/entities/network/payments/NetworkPaymentRequestQuery"

const basePath = "/payments"

export default class PaymentsNetworkSource {
  private readonly backendHttpClient: BackendHttpClient

  constructor(parameters: {
    readonly backendHttpClient: BackendHttpClient
  }) {
    this.backendHttpClient = parameters.backendHttpClient
  }

  async getPayment({
    paymentId,
    paymentToken
  }: {
    readonly paymentId: number
    readonly paymentToken: string
  }): Promise<GetPaymentNetworkResult> {
    const result: BackendHttpClientResult = await this.backendHttpClient.executeRequest({
      type: HttpRequestType.GET,
      path: `${basePath}/${paymentId}`,
      parameters: instanceToPlain(new NetworkPaymentRequestQuery({
        paymentToken
      }))
    })

    switch (result.type) {
      case "success": {
        return {
          type: "success",
          data: plainToInstance(NetworkPaymentResponseBody, result.body).payment!
        }
      }
      case "error":
        return {
          type: "error",
          error: plainToInstance(NetworkPaymentError, result.body)
        }
      case "failure":
        return result
      default:
        assertNever(result)
    }
  }
}

export type GetPaymentNetworkResult =
  SuccessExecutionResult<NetworkPayment> |
  ErrorExecutionResult<NetworkExecutionError> |
  FailureExecutionResult
