import PaymentsRepository from "../../domain/repositories/PaymentsRepository"
import DiModule from "../../../../lib/di/DiModule"
import CoreDataDiModule from "../../../../core/di/modules/CoreDataDiModule"
import DefaultPaymentsRepository from "../../data/DefaultPaymentsRepository"

export default interface PaymentsDataDiModule {
  providePaymentsRepository(): PaymentsRepository
}

export class DefaultPaymentsDataDiModule
  extends DiModule
  implements PaymentsDataDiModule {

  private readonly coreDataDiModule: CoreDataDiModule

  constructor(parameters: {
    readonly coreDataDiModule: CoreDataDiModule
  }) {
    super()
    this.coreDataDiModule = parameters.coreDataDiModule
  }

  providePaymentsRepository(): PaymentsRepository {
    return this.single(
      DefaultPaymentsRepository.name,
      () => new DefaultPaymentsRepository({
        paymentsNetworkDataSource: this.coreDataDiModule.providePaymentsNetworkSource()
      })
    )
  }
}
