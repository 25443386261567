import EcommerceDi from "./di/EcommerceDi"
import { CoreTextProviderProvider } from "./core/presentation/contexts/CoreTextProviderContext"
import { RouterProvider } from "react-router-dom"
import createEcommerceBrowserRouter from "./routing/createEcommerceBrowserRouter"
import React from "react"
import CoreI18n from "./core/i18n/CoreI18n"

export interface EcommerceAppProps {
  readonly di: EcommerceDi
}

export default function EcommerceApp({ di }: EcommerceAppProps) {
  const coreI18n: CoreI18n = di.coreDiComponent
    .coreI18nDiModule
    .provideCoreI18n()

  const router = createEcommerceBrowserRouter({ di })

  return (
    <CoreTextProviderProvider coreI18n={coreI18n}>
      <RouterProvider router={router} />
    </CoreTextProviderProvider>
  )
}
