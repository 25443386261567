import { RouteObject } from "react-router-dom"
import PaymentsDi from "../di/PaymentsDi"
import CoreUrlProvider from "../../../core/presentation/services/CoreUrlProvider"
import { objectIdUrlPart } from "../../../core/presentation/hooks/useObjectId"

export default function createPaymentsRoutes({ di }: { readonly di: PaymentsDi }): RouteObject[] {
  const urlProvider: CoreUrlProvider = new CoreUrlProvider()

  return [
    {
      path: urlProvider.buildPaymentUrl({ id: `:${objectIdUrlPart}` }),
      element: di.paymentsDiComponent.paymentsPresentationDiModule.providePaymentPage()
    },
    {
      path: urlProvider.buildPaymentStatusUrl({ id: `:${objectIdUrlPart}` }),
      element: di.paymentsDiComponent.paymentsPresentationDiModule.providePaymentStatusPage()
    },
    {
      path: urlProvider.buildPaymentSuccessUrl(),
      element: di.paymentsDiComponent.paymentsPresentationDiModule.providePaymentSuccessPage()
    },
    {
      path: urlProvider.buildPaymentErrorUrl(),
      element: di.paymentsDiComponent.paymentsPresentationDiModule.providePaymentErrorPage()
    }
  ]
}
