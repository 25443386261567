import { Expose, Type } from "class-transformer"
import NetworkPaymentStatus from "../payment-statuses/NetworkPaymentStatus"

export default class NetworkPayment {

  @Expose()
  @Type(() => Number)
  readonly id: number | null | undefined

  @Expose()
  @Type(() => String)
  readonly paymentProviderPaymentPageUrl: string | null | undefined

  @Expose()
  @Type(() => NetworkPaymentStatus)
  readonly status: NetworkPaymentStatus | null | undefined
}
