import PaymentsDiComponent from "../../sqcore/features/payments/di/components/PaymentsDiComponent"
import EcommerceDi from "../../sqcore/di/EcommerceDi"
import CoreDiComponent from "../../sqcore/core/di/components/CoreDiComponent"
import CoreI18nDiModule, { DefaultCoreI18nDiModule } from "../../sqcore/core/di/modules/CoreI18nDiModule"
import DefaultRuCoreTextProvider from "../../sqcore/core/i18n/core-text-providers/DefaultRuCoreTextProvider"
import CoreNetworkDiModule, { DefaultCoreNetworkDiModule } from "../../sqcore/core/di/modules/CoreNetworkDiModule"
import {
  DefaultPaymentsPresentationDiModule
} from "../../sqcore/features/payments/di/modules/PaymentsPresentationDiModule"
import { DefaultPaymentsDomainDiModule } from "../../sqcore/features/payments/di/modules/PaymentsDomainDiModule"
import { DefaultPaymentsDataDiModule } from "../../sqcore/features/payments/di/modules/PaymentsDataDiModule"
import { DefaultCoreDataDiModule } from "../../sqcore/core/di/modules/CoreDataDiModule"

const defaultLanguage = "RU"
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL!

export default class Di implements EcommerceDi {
  readonly coreDiComponent: CoreDiComponent
  readonly paymentsDiComponent: PaymentsDiComponent

  constructor(parameters: {
    readonly coreDiComponent: CoreDiComponent
    readonly rootDiComponent: PaymentsDiComponent

  }) {
    this.coreDiComponent = parameters.coreDiComponent
    this.paymentsDiComponent = parameters.rootDiComponent
  }
}

export function createDi(): Di {
  const coreI18nDiModule: CoreI18nDiModule = new DefaultCoreI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuCoreTextProvider()
    }
  })

  const coreNetworkDiModule: CoreNetworkDiModule = new DefaultCoreNetworkDiModule({
    backendBaseUrl
  })

  const coreDataDiModule = new DefaultCoreDataDiModule({
    coreNetworkDiModule
  })

  return new Di({
    coreDiComponent: {
      coreI18nDiModule
    },
    rootDiComponent: {
      paymentsPresentationDiModule: new DefaultPaymentsPresentationDiModule({
        paymentsDomainDiModule: new DefaultPaymentsDomainDiModule({
          paymentsDataDiModule: new DefaultPaymentsDataDiModule({
            coreDataDiModule
          })
        })
      })
    }
  })
}
