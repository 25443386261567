import { Expose, Type } from "class-transformer"

export default class NetworkPaymentRequestQuery {
  @Expose()
  @Type(() => String)
  readonly paymentToken: string | null | undefined

  constructor(parameters: {
    readonly paymentToken?: string | null
  }) {
    this.paymentToken = parameters.paymentToken
  }
}
