import PaymentsRepository from "../repositories/PaymentsRepository"
import SuccessExecutionResult from "../../../../core/domain/results/SuccessExecutionResult"
import Payment from "../../../../core/domain/entities/payments/Payment"
import ErrorExecutionResult from "../../../../core/domain/results/ErrorExecutionResult"
import ExecutionError from "../../../../core/domain/entities/ExecutionError"
import FailureExecutionResult from "../../../../core/domain/results/FailureExecutionResult"

export interface GetPaymentParameters {
  readonly paymentId: number
  readonly paymentToken: string
}

export default class GetPaymentUseCase {

  private readonly paymentsRepository: PaymentsRepository

  constructor(parameters: {
    readonly paymentsRepository: PaymentsRepository
  }) {
    this.paymentsRepository = parameters.paymentsRepository
  }

  async execute(parameters: GetPaymentParameters): Promise<GetPaymentResult> {
    return this.paymentsRepository.getPayment(parameters)
  }
}

export type GetPaymentResult =
  SuccessExecutionResult<Payment> |
  ErrorExecutionResult<ExecutionError> |
  FailureExecutionResult
