import BackendHttpClient from "../../data/network/BackendHttpClient"
import DiModule from "../../../lib/di/DiModule"
import HttpClient from "../../../lib/http-client/HttpClient"

export default interface CoreNetworkDiModule {
  provideBackendHttpClient(): BackendHttpClient
}

export class DefaultCoreNetworkDiModule
  extends DiModule
  implements CoreNetworkDiModule {

  private readonly backendBaseUrl: string

  constructor(parameters: {
    readonly backendBaseUrl: string
  }) {
    super()
    this.backendBaseUrl = parameters.backendBaseUrl
  }

  provideBackendHttpClient(): BackendHttpClient {
    return this.single(
      BackendHttpClient.name,
      () => new BackendHttpClient({
        httpClient: new HttpClient({
          baseUrl: this.backendBaseUrl
        })
      })
    )
  }
}
