import NetworkPayment from "../entites/entities/network/payments/NetworkPayment"
import Payment from "../../domain/entities/payments/Payment"
import PaymentStatusesMapper from "./PaymentStatusesMapper"

export default class PaymentsMapper {

  mapNetworkToDomain({
    payment
  }: {
    readonly payment: NetworkPayment
  }): Payment {
    const paymentStatusesMapper = new PaymentStatusesMapper()

    return {
      id: payment.id,
      paymentProviderPaymentPageUrl: payment.paymentProviderPaymentPageUrl,
      status: payment.status && paymentStatusesMapper.mapNetworkToDomain({
        paymentStatus: payment.status
      })
    }
  }
}
