import React, { useLayoutEffect, useMemo } from "react"
import { useStateObservable } from "../../../../../lib/view-model/StateObservable"
import assertNever from "../../../../../lib/assertNever"
import PaymentViewModel, { ProvidePaymentViewModelParameters } from "./PaymentViewModel"
import { PaymentViewState } from "./PaymentViewState"
import { useNumberObjectId } from "../../../../../core/presentation/hooks/useObjectId"
import usePaymentToken from "../../../../../core/presentation/hooks/usePaymentToken"

interface Props {
  readonly provideViewModel: (parameters: ProvidePaymentViewModelParameters) => PaymentViewModel
}

export default function PaymentPage({ provideViewModel }: Props) {
  const paymentToken: string = usePaymentToken()
  const paymentId: number = useNumberObjectId()

  const viewModel: PaymentViewModel = useMemo<PaymentViewModel>(() => {
    return provideViewModel({ paymentId, paymentToken })
  }, [])

  const paymentViewState: PaymentViewState = useStateObservable(viewModel.paymentStateObservable)

  useLayoutEffect(() => {
    if (paymentViewState.type === "external_payment") {
      window.location.assign(paymentViewState.paymentUrl)
    }
  }, [paymentViewState])

  switch (paymentViewState.type) {
    case "initial":
      return <div></div>
    case "fetch_payment_error":
      return <div>{paymentViewState.message}</div>
    case "fetch_payment_failure":
      return <div>{paymentViewState.message}</div>
    case "external_payment":
      return <></>
    default:
      assertNever(paymentViewState)
  }
}
