import React from "react"
import Di, { createDi } from "./di/Di"
import EcommerceApp from "../sqcore/EcommerceApp"

// TODO: think how to run app with another DI.
const di: Di = createDi()

export default function App() {
  return <EcommerceApp di={di} />
}
