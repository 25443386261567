import { Expose, Type } from "class-transformer"

export default class NetworkPaymentStatus {

  @Expose()
  @Type(() => Number)
  readonly id: number | null | undefined

  @Expose()
  @Type(() => String)
  readonly name: string | null | undefined

  @Expose()
  @Type(() => Boolean)
  readonly isPending: boolean | null | undefined

  @Expose()
  @Type(() => Boolean)
  readonly isProcessing: boolean | null | undefined

  @Expose()
  @Type(() => Boolean)
  readonly isPaid: boolean | null | undefined

  @Expose()
  @Type(() => Boolean)
  readonly isFailed: boolean | null | undefined
}
