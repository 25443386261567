import NetworkPaymentStatus from "../entites/entities/network/payment-statuses/NetworkPaymentStatus"
import PaymentStatus from "../../domain/entities/payment-statuses/PaymentStatus"

export default class PaymentStatusesMapper {

  mapNetworkToDomain({
    paymentStatus
  }: {
    readonly paymentStatus: NetworkPaymentStatus
  }): PaymentStatus {
    return {
      id: paymentStatus.id,
      name: paymentStatus.name,
      isFailed: paymentStatus.isFailed,
      isPending: paymentStatus.isPending,
      isPaid: paymentStatus.isPaid,
      isProcessing: paymentStatus.isProcessing
    }
  }
}
