import React, { ReactElement } from "react"
import DiModule from "../../../../lib/di/DiModule"
import PaymentStatusViewModel, {
  ProvidePaymentStatusViewModelParameters
} from "../../presentation/pages/payment-status/PaymentStatusViewModel"
import PaymentStatusPage from "../../presentation/pages/payment-status/PaymentStatusPage"
import PaymentSuccessPage from "../../presentation/pages/payment-success/PaymentSuccessPage"
import PaymentErrorPage from "../../presentation/pages/payment-error/PaymentErrorPage"
import PaymentPage from "../../presentation/pages/payment/PaymentPage"
import PaymentViewModel, { ProvidePaymentViewModelParameters } from "../../presentation/pages/payment/PaymentViewModel"
import PaymentsDomainDiModule from "./PaymentsDomainDiModule"
import autoBind from "auto-bind"

export default interface PaymentsPresentationDiModule {
  providePaymentPage(): ReactElement

  providePaymentStatusPage(): ReactElement

  providePaymentSuccessPage(): ReactElement

  providePaymentErrorPage(): ReactElement
}

export class DefaultPaymentsPresentationDiModule extends DiModule implements PaymentsPresentationDiModule {

  private readonly paymentsDomainDiModule: PaymentsDomainDiModule

  constructor(parameters: {
    readonly paymentsDomainDiModule: PaymentsDomainDiModule
  }) {
    super()

    this.paymentsDomainDiModule = parameters.paymentsDomainDiModule

    autoBind(this)
  }

  providePaymentPage(): React.ReactElement {
    return <PaymentPage provideViewModel={this.providePaymentViewModel} />
  }

  providePaymentStatusPage(): ReactElement {
    return <PaymentStatusPage provideViewModel={this.providePaymentStatusViewModel} />
  }

  providePaymentSuccessPage(): ReactElement {
    return <PaymentSuccessPage />
  }

  providePaymentErrorPage(): React.ReactElement {
    return <PaymentErrorPage />
  }

  private providePaymentViewModel({
    paymentToken,
    paymentId
  }: ProvidePaymentViewModelParameters): PaymentViewModel {
    return new PaymentViewModel({
      paymentId,
      paymentToken,
      getPaymentUseCase: this.paymentsDomainDiModule.provideGetPaymentUseCase()
    })
  }

  private providePaymentStatusViewModel({
    paymentToken,
    paymentId
  }: ProvidePaymentStatusViewModelParameters): PaymentStatusViewModel {
    return new PaymentStatusViewModel({
      paymentId,
      paymentToken,
      getPaymentUseCase: this.paymentsDomainDiModule.provideGetPaymentUseCase()
    })
  }
}
