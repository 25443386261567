import UrlProvider from "./UrlProvider"

export default class CoreUrlProvider extends UrlProvider {
  buildPaymentUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildPaymentPath({ id }) })
  }

  buildPaymentPath({ id }: { readonly id: number | string }): string {
    return `payments/${id}`
  }

  buildPaymentStatusUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildPaymentStatusPath({ id }) })
  }

  buildPaymentStatusPath({ id }: { readonly id: number | string }): string {
    return `payments/${id}/status`
  }

  buildPaymentSuccessUrl(): string {
    return this.buildUrl({ path: this.buildPaymentSuccessPath() })
  }

  buildPaymentSuccessPath(): string {
    return "payment_success"
  }

  buildPaymentErrorUrl(): string {
    return this.buildUrl({ path: this.buildPaymentErrorPath() })
  }

  buildPaymentErrorPath(): string {
    return "payment_error"
  }
}
