import HttpClient, { HttpClientResult, HttpRequestType } from "../../../lib/http-client/HttpClient"
import NetworkRequestException from "./NetworkRequestException"

export default class BackendHttpClient {
  private readonly httpClient: HttpClient

  constructor(parameters: {
    readonly httpClient: HttpClient
  }) {
    this.httpClient = parameters.httpClient
  }

  async executeRequest({
    type,
    path,
    parameters,
    body
  }: {
    readonly type: HttpRequestType
    readonly path: string
    readonly parameters?: unknown
    readonly body?: object
  }): Promise<BackendHttpClientResult> {
    const httpClientResult: HttpClientResult = await this.httpClient.executeRequest({
      type,
      path,
      parameters,
      body: body && JSON.stringify(body),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })

    switch (httpClientResult.type) {
      case "http_client_success":
        return {
          type: "success",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_error":
        return {
          type: "error",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_failure":
        return {
          type: "failure",
          // TODO: architecture. Map concrete exceptions to concrete exceptions.
          exception: new NetworkRequestException()
        }
    }
  }

  async executeFormDataRequest({
    type,
    path,
    parameters,
    formData
  }: {
    readonly type: HttpRequestType
    readonly path: string
    readonly parameters?: unknown
    readonly formData: FormData
  }): Promise<BackendHttpClientResult> {
    const httpClientResult: HttpClientResult = await this.httpClient.executeRequest({
      type,
      path,
      parameters,
      body: formData,
      headers: {
        "Accept": "application/json"
      }
    })

    switch (httpClientResult.type) {
      case "http_client_success":
        return {
          type: "success",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_error":
        return {
          type: "error",
          body: JSON.parse(httpClientResult.body),
          status: httpClientResult.status
        }
      case "http_client_failure":
        return {
          type: "failure",
          // TODO: architecture. Map concrete exceptions to concrete exceptions.
          exception: new NetworkRequestException()
        }
    }
  }
}

export interface SuccessBackendHttpClientResult {
  readonly type: "success"
  readonly body?: object | null
  readonly status: number
}

export interface ErrorBackendHttpClientResult {
  readonly type: "error"
  readonly body?: object | null
  readonly status: number
}

export interface FailureBackendHttpClientResult {
  readonly type: "failure"
  readonly exception: NetworkRequestException
}

export type BackendHttpClientResult =
  SuccessBackendHttpClientResult |
  ErrorBackendHttpClientResult |
  FailureBackendHttpClientResult
