import PaymentsRepository from "../domain/repositories/PaymentsRepository"
import PaymentsNetworkSource from "../../../core/data/sources/network/PaymentsNetworkSource"
import { GetPaymentParameters, GetPaymentResult } from "../domain/use-cases/GetPaymentUseCase"
import assertNever from "../../../lib/assertNever"
import PaymentsMapper from "../../../core/data/mappers/PaymentsMapper"

export default class DefaultPaymentsRepository implements PaymentsRepository {

  private readonly paymentsNetworkDataSource: PaymentsNetworkSource

  constructor(parameters: {
    readonly paymentsNetworkDataSource: PaymentsNetworkSource
  }) {
    this.paymentsNetworkDataSource = parameters.paymentsNetworkDataSource
  }

  async getPayment(parameters: GetPaymentParameters): Promise<GetPaymentResult> {
    const result = await this.paymentsNetworkDataSource.getPayment(parameters)

    switch (result.type) {
      case "success": {
        return {
          type: "success",
          data: new PaymentsMapper().mapNetworkToDomain({ payment: result.data })
        }
      }
      case "error": {
        return result
      }
      case "failure":
        return result
      default:
        assertNever(result)
    }
  }
}
