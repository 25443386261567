import { PaymentStatusViewState } from "./PaymentStatusViewState"
import ViewModel from "../../../../../lib/view-model/ViewModel"
import autoBind from "auto-bind"
import { StateObservable } from "../../../../../lib/view-model/StateObservable"
import sleep from "../../../../../lib/sleep"
import GetPaymentUseCase from "../../../domain/use-cases/GetPaymentUseCase"

const refreshTimeoutInterval = 500

export interface ProvidePaymentStatusViewModelParameters {
  readonly paymentToken: string
  readonly paymentId: number
}

export default class PaymentStatusViewModel extends ViewModel {
  paymentStatusStateObservable: StateObservable<PaymentStatusViewState> =
    new StateObservable<PaymentStatusViewState>({ type: "initial" })

  private readonly getPaymentUseCase: GetPaymentUseCase
  private readonly paymentToken: string
  private readonly paymentId: number

  constructor(parameters: {
    readonly getPaymentUseCase: GetPaymentUseCase
    readonly paymentToken: string
    readonly paymentId: number
  }) {
    super()

    this.paymentId = parameters.paymentId
    this.paymentToken = parameters.paymentToken
    this.getPaymentUseCase = parameters.getPaymentUseCase
    autoBind(this)
    this.fetchPayment().then()
  }

  private async fetchPayment(): Promise<void> {
    const result = await this.getPaymentUseCase.execute({
      paymentId: this.paymentId,
      paymentToken: this.paymentToken
    })

    switch (result.type) {
      case "success":
        break
      case "error":
        return this.paymentStatusStateObservable.setValue({
          type: "refresh_payment_error",
          message: result.error.message
        })
      case "failure":
        return this.paymentStatusStateObservable.setValue({
          type: "refresh_payment_failure",
          message: result.exception.message
        })
    }

    const payment = result.data!
    const paymentStatus = payment.status!
    if (paymentStatus.isFailed) {
      return this.paymentStatusStateObservable.setValue({
        type: "error"
      })
    }

    if (paymentStatus.isPaid) {
      return this.paymentStatusStateObservable.setValue({
        type: "success"
      })
    }

    if (paymentStatus.isProcessing) {
      await sleep(refreshTimeoutInterval)
      this.fetchPayment().then()
      return
    }

    if (paymentStatus.isPending) {
      return this.paymentStatusStateObservable.setValue({
        type: "pending",
        paymentUrl: payment.paymentProviderPaymentPageUrl!
      })
    }
  }
}
