import GetPaymentUseCase from "../../domain/use-cases/GetPaymentUseCase"
import PaymentsDataDiModule from "./PaymentsDataDiModule"
import DiModule from "../../../../lib/di/DiModule"

export default interface PaymentsDomainDiModule {
  provideGetPaymentUseCase(): GetPaymentUseCase
}

export class DefaultPaymentsDomainDiModule
  extends DiModule
  implements PaymentsDomainDiModule {

  private readonly paymentsDataDiModule: PaymentsDataDiModule

  constructor(parameters: {
    readonly paymentsDataDiModule: PaymentsDataDiModule
  }) {
    super()
    this.paymentsDataDiModule = parameters.paymentsDataDiModule
  }

  provideGetPaymentUseCase(): GetPaymentUseCase {
    return this.single(
      GetPaymentUseCase.name,
      () => new GetPaymentUseCase({
        paymentsRepository: this.paymentsDataDiModule.providePaymentsRepository()
      })
    )
  }
}
