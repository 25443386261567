import ViewModel from "../../../../../lib/view-model/ViewModel"
import autoBind from "auto-bind"
import { StateObservable } from "../../../../../lib/view-model/StateObservable"
import { PaymentViewState } from "./PaymentViewState"
import GetPaymentUseCase from "../../../domain/use-cases/GetPaymentUseCase"

export interface ProvidePaymentViewModelParameters {
  readonly paymentId: number
  readonly paymentToken: string
}

export default class PaymentViewModel extends ViewModel {
  paymentStateObservable: StateObservable<PaymentViewState> =
    new StateObservable<PaymentViewState>({ type: "initial" })

  private readonly getPaymentUseCase: GetPaymentUseCase
  private readonly paymentId: number
  private readonly paymentToken: string

  constructor(parameters: {
    readonly getPaymentUseCase: GetPaymentUseCase
    readonly paymentId: number
    readonly paymentToken: string
  }) {
    super()

    this.paymentId = parameters.paymentId
    this.paymentToken = parameters.paymentToken
    this.getPaymentUseCase = parameters.getPaymentUseCase
    autoBind(this)
    this.getPayment().then()
  }

  private async getPayment(): Promise<void> {
    const result = await this.getPaymentUseCase.execute({
      paymentId: this.paymentId,
      paymentToken: this.paymentToken
    })

    switch (result.type) {
      case "success":
        return this.paymentStateObservable.setValue({
          type: "external_payment",
          paymentUrl: result.data.paymentProviderPaymentPageUrl!
        })
      case "error":
        return this.paymentStateObservable.setValue({
          type: "fetch_payment_error",
          message: result.error.message
        })
      case "failure":
        return this.paymentStateObservable.setValue({
          type: "fetch_payment_failure",
          message: result.exception.message
        })
    }
  }
}
