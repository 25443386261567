import PaymentsNetworkSource from "../../data/sources/network/PaymentsNetworkSource"
import DiModule from "../../../lib/di/DiModule"
import CoreNetworkDiModule from "./CoreNetworkDiModule"

export default interface CoreDataDiModule {

  providePaymentsNetworkSource(): PaymentsNetworkSource
}

export class DefaultCoreDataDiModule extends DiModule implements CoreDataDiModule {
  private readonly coreNetworkDiModule: CoreNetworkDiModule

  constructor(parameters: {
    readonly coreNetworkDiModule: CoreNetworkDiModule
  }) {
    super()
    this.coreNetworkDiModule = parameters.coreNetworkDiModule
  }

  providePaymentsNetworkSource(): PaymentsNetworkSource {
    return this.single(
      PaymentsNetworkSource.name,
      () => new PaymentsNetworkSource({
        backendHttpClient: this.coreNetworkDiModule.provideBackendHttpClient()
      })
    )
  }
}
