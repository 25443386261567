import { Params, useParams } from "react-router-dom"

export const objectIdUrlPart = "objectId"

export default function useObjectId(): string {
  const urlParams: Readonly<Params> = useParams()
  return urlParams[objectIdUrlPart] ?? ""
}

export function useNumberObjectId(): number {
  const objectId: string | undefined = useObjectId()
  return parseInt(objectId)
}
