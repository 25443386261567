import React, { useLayoutEffect, useMemo } from "react"
import PaymentStatusViewModel, { ProvidePaymentStatusViewModelParameters } from "./PaymentStatusViewModel"
import assertNever from "../../../../../lib/assertNever"
import { useStateObservable } from "../../../../../lib/view-model/StateObservable"
import { PaymentStatusViewState } from "./PaymentStatusViewState"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import { useNumberObjectId } from "../../../../../core/presentation/hooks/useObjectId"
import usePaymentToken from "../../../../../core/presentation/hooks/usePaymentToken"

interface Props {
  readonly provideViewModel: (parameters: ProvidePaymentStatusViewModelParameters) => PaymentStatusViewModel
}

export default function PaymentStatusPage({ provideViewModel }: Props) {
  const paymentToken: string = usePaymentToken()
  const paymentId: number = useNumberObjectId()

  const viewModel: PaymentStatusViewModel = useMemo<PaymentStatusViewModel>(() => {
    return provideViewModel({ paymentId, paymentToken })
  }, [])

  const paymentStatusViewState: PaymentStatusViewState = useStateObservable(viewModel.paymentStatusStateObservable)

  useLayoutEffect(() => {
    if (paymentStatusViewState.type === "pending") {
      window.location.assign(paymentStatusViewState.paymentUrl)
    }

    if (paymentStatusViewState.type === "success") {
      const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
      window.location.assign(coreUrlProvider.buildPaymentSuccessUrl())
    }

    if (paymentStatusViewState.type === "error") {
      const coreUrlProvider: CoreUrlProvider = new CoreUrlProvider()
      window.location.assign(coreUrlProvider.buildPaymentErrorUrl())
    }
  }, [paymentStatusViewState])

  switch (paymentStatusViewState.type) {
    case "initial":
      return <div></div>
    case "refresh_payment_error":
      return <div>{paymentStatusViewState.message}</div>
    case "refresh_payment_failure":
      return <div>{paymentStatusViewState.message}</div>
    case "pending":
      return <div></div>
    case "processing":
      return <div></div>
    case "success":
      return <></>
    case "error":
      return <></>
    default:
      assertNever(paymentStatusViewState)
  }
}
